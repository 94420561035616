define("ember-google-maps-wf/components/g-map-addons/sidebar", ["exports", "@ember/component", "@ember/template-factory", "ember-google-maps/components/g-map/map-component"], function (_exports, _component, _templateFactory, _mapComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#in-element this.sidebar}}
    {{yield}}
  {{/in-element}}
  */
  {
    "id": "QyUg4vfK",
    "block": "[[[40,[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]],\"%cursor:0%\",[28,[37,1],[[30,0,[\"sidebar\"]]],null]]],[\"&default\"],false,[\"in-element\",\"-in-el-null\",\"yield\"]]",
    "moduleName": "ember-google-maps-wf/components/g-map-addons/sidebar.hbs",
    "isStrictMode": false
  });
  /* global document */

  class Sidebar extends _mapComponent.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "sidebar", document.createElement('div'));
    }
    get name() {
      return 'sidebar';
    }
    setup() {
      let div = this.map.getDiv().firstElementChild;
      div.appendChild(this.sidebar);
      if (this.args.class) {
        this.args.class.split(' ').forEach(cl => this.sidebar.classList.add(cl));
      }
      return div;
    }
    update() {
      let sidebar = this.sidebar;
      let options = this.options;
      if (options.class) {
        sidebar.removeAttribute('class');
        options.class.split(' ').forEach(cl => sidebar.classList.add(cl));
      }
    }
  }
  _exports.default = Sidebar;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Sidebar);
});