define("google-maps-data/components/g-map-addons/feature", ["exports", "@ember/component", "@ember/template-factory", "ember-google-maps/components/g-map/typical-map-component"], function (_exports, _component, _templateFactory, _typicalMapComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield}}
  */
  {
    "id": "eWxqqjEt",
    "block": "[[[18,1,null]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "google-maps-data/components/g-map-addons/feature.hbs",
    "isStrictMode": false
  });
  class Feature extends _typicalMapComponent.default {
    get name() {
      return 'feature';
    }
    get newOptions() {
      return this.args.feature;
    }
    newMapComponent(options = {}) {
      return new google.maps.Data.Feature(options);
    }
    setup() {
      // we don't call super because we shouldn't register this component against the map
      let mapComponent = this.newMapComponent(this.newOptions);
      this.addEventsToMapComponent(mapComponent, this.events, this.publicAPI);
      this.args.data.add(this.args.feature);

      // what gets returned here gets set as the 'this.mapComponent'
      // if this isn't set, the teardown fn won't be called..
      // used in our test system as well to be able to ask questions of this particular data value
      return mapComponent;
    }
    teardown(mapComponent) {
      this.boundEvents.forEach(({
        remove
      }) => remove());
      this.args.data.remove(mapComponent);

      // Unregister from the parent component
      this.onTeardown?.();
    }
  }
  _exports.default = Feature;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Feature);
});