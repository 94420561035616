define("ember-power-select-typeahead/templates/components/power-select-typeahead/trigger", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "XVNdd+TX",
    "block": "[[[10,\"input\"],[15,2,[30,0,[\"text\"]]],[14,\"role\",\"combobox\"],[15,1,[29,[\"ember-power-select-typeahead-input-\",[30,1,[\"uniqueId\"]]]]],[14,0,\"ember-power-select-typeahead-input ember-power-select-search-input\"],[14,\"autocomplete\",\"off\"],[14,\"autocorrect\",\"off\"],[14,\"autocapitalize\",\"off\"],[14,\"spellcheck\",\"false\"],[15,\"placeholder\",[30,2]],[15,\"oninput\",[30,3]],[15,\"onfocus\",[30,4]],[15,\"onblur\",[30,5]],[15,\"disabled\",[30,1,[\"disabled\"]]],[15,\"onkeydown\",[28,[37,0],[[30,0],\"handleKeydown\"],null]],[15,\"onmousedown\",[28,[37,0],[[30,0],\"stopPropagation\"],null]],[14,4,\"search\"],[12],[13],[1,\"\\n\"],[41,[30,1,[\"loading\"]],[[[1,\"  \"],[10,1],[14,0,\"ember-power-select-typeahead-loading-indicator\"],[12],[13],[1,\"\\n\"]],[]],null]],[\"@select\",\"@placeholder\",\"@onInput\",\"@onFocus\",\"@onBlur\"],false,[\"action\",\"if\"]]",
    "moduleName": "ember-power-select-typeahead/templates/components/power-select-typeahead/trigger.hbs",
    "isStrictMode": false
  });
});