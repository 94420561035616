define("ember-power-select-typeahead/components/power-select-typeahead", ["exports", "@ember/component", "@ember/object", "ember-power-select-typeahead/templates/components/power-select-typeahead"], function (_exports, _component, _object, _powerSelectTypeahead) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    tagName: '',
    layout: _powerSelectTypeahead.default,
    tabindex: -1,
    triggerComponent: 'power-select-typeahead/trigger',
    beforeOptionsComponent: null,
    loadingMessage: null,
    noMatchesMessage: null,
    onkeydown: () => {},
    // CPs
    concatenatedTriggerClasses: (0, _object.computed)('triggerClass', function () {
      let classes = ['ember-power-select-typeahead-trigger'];
      let passedClass = this.get('triggerClass');
      if (passedClass) {
        classes.push(passedClass);
      }
      return classes.join(' ');
    }),
    concatenatedDropdownClasses: (0, _object.computed)('dropdownClass', function () {
      let classes = ['ember-power-select-typeahead-dropdown'];
      let passedClass = this.get('dropdownClass');
      if (passedClass) {
        classes.push(passedClass);
      }
      return classes.join(' ');
    }),
    actions: {
      onKeyDown(select, e) {
        let action = this.get('onkeydown');

        // if user passes `onkeydown` action
        if (action && action(select, e) === false) {
          return false;
        } else {
          // if escape, then clear out selection
          if (e.keyCode === 27) {
            select.actions.choose(null);
          }
        }
      }
    }
  });
});