define("ember-google-maps-drawing/components/g-map-addons/drawing", ["exports", "@ember/component", "@ember/template-factory", "ember-google-maps/components/g-map/map-component"], function (_exports, _component, _templateFactory, _mapComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield}}
  */
  {
    "id": "+vo5IRb/",
    "block": "[[[18,1,null]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-google-maps-drawing/components/g-map-addons/drawing.hbs",
    "isStrictMode": false
  });
  /* global google */

  class Drawing extends _mapComponent.default {
    get name() {
      return 'drawing';
    }
    setup() {
      let drawingManager = new google.maps.drawing.DrawingManager(this._getOptions());
      drawingManager.setMap(this.map);
      this.addEventsToMapComponent(drawingManager, this.events, this.publicAPI);
      return drawingManager;
    }
    _getOptions() {
      let options = {};
      ['drawingMode', 'drawingControl', 'markerOptions', 'polygonOptions'].forEach(prop => options[prop] = this.args[prop]);
      return options;
    }
    update(mapComponent) {
      mapComponent.setOptions(this._getOptions());
    }
    teardown() {
      this.mapComponent.setMap(null);
    }
  }
  _exports.default = Drawing;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Drawing);
});