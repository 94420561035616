define("ember-power-select-typeahead/components/power-select-typeahead/trigger", ["exports", "@ember/component", "@ember/utils", "@ember/runloop", "ember-power-select-typeahead/templates/components/power-select-typeahead/trigger"], function (_exports, _component, _utils, _runloop, _trigger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _trigger.default,
    tagName: '',
    text: '',
    /**
     * Lifecycle Hook
     * power-select updates the state of the publicAPI (select) for every typeahead
     * so we capture this as `state` via oldSelect && newSelect
     *
     * @private
     * @method didReceiveAttrs
     */
    didReceiveAttrs() {
      this._super(...arguments);
      let oldSelect = this.get('oldSelect');
      let newSelect = this.set('oldSelect', this.get('select'));
      if (!oldSelect) {
        return;
      }
      /*
       * We need to update the input field with value of the selected option whenever we're closing
       * the select box.
       */
      if (oldSelect.isOpen && !newSelect.isOpen && newSelect.searchText) {
        let input = document.querySelector(`#ember-power-select-typeahead-input-${newSelect.uniqueId}`);
        let newText = this.getSelectedAsText();
        if (input.value !== newText) {
          input.value = newText;
        }
        this.set('text', newText);
      }
      if (newSelect.lastSearchedText !== oldSelect.lastSearchedText) {
        if ((0, _utils.isBlank)(newSelect.lastSearchedText)) {
          (0, _runloop.schedule)('actions', null, newSelect.actions.close, null, true);
        } else {
          (0, _runloop.schedule)('actions', null, newSelect.actions.open);
        }
      }
      if (oldSelect.selected !== newSelect.selected) {
        this.set('text', this.getSelectedAsText());
      }
    },
    actions: {
      /**
       * on mousedown prevent propagation of event
       *
       * @private
       * @method stopPropagation
       * @param {Object} event
       */
      stopPropagation(e) {
        e.stopPropagation();
      },
      /**
       * called from power-select internals
       *
       * @private
       * @method handleKeydown
       * @param {Object} event
       */
      handleKeydown(e) {
        // up or down arrow and if not open, no-op and prevent parent handlers from being notified
        if ([38, 40].indexOf(e.keyCode) > -1 && !this.get('select.isOpen')) {
          e.stopPropagation();
          return;
        }
        let isLetter = e.keyCode >= 48 && e.keyCode <= 90 || e.keyCode === 32; // Keys 0-9, a-z or SPACE
        // if isLetter, escape or enter, prevent parent handlers from being notified
        if (isLetter || [13, 27].indexOf(e.keyCode) > -1) {
          let select = this.get('select');
          // open if loading msg configured
          if (!select.isOpen && this.get('loadingMessage')) {
            (0, _runloop.schedule)('actions', null, select.actions.open);
          }
          e.stopPropagation();
        }

        // optional, passed from power-select
        let onkeydown = this.get('onKeydown');
        if (onkeydown && onkeydown(e) === false) {
          return false;
        }
      }
    },
    /**
     * obtains seleted value based on complex object or primitive value from power-select publicAPI
     *
     * @private
     * @method getSelectedAsText
     */
    getSelectedAsText() {
      let labelPath = this.get('extra.labelPath');
      let value;
      if (labelPath) {
        // complex object
        value = this.get(`select.selected.${labelPath}`);
      } else {
        // primitive value
        value = this.get('select.selected');
      }
      if (value === undefined) {
        value = '';
      }
      return value;
    }
  });
});